import React from 'react'

import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

import Moment from 'moment';
import 'moment/locale/es';

import { truncateString } from '../../../utilities/helper-functions'

import './Styles.scss';

export default function ClientCard({entry, listStyle, openEditClientModal}) {
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user?.data?.permissionsData;

    return (
        listStyle ? (
            <ListCard
                entry={entry}
                openEditClientModal={openEditClientModal}
                userPermissions={userPermissions}
                user={user}
            />
        ) : (
            <GridCard
                entry={entry}
                openEditClientModal={openEditClientModal}
                userPermissions={userPermissions}
                user={user}
            />
        )
    )
}

const ListCard = ({entry, openEditClientModal, userPermissions, user}) => (
    <div className='client-card client-card-list-style'>
        <Link className='client-card-link' to={`/client-detail/${entry.id}`}>
            <div className='client-card-details'>
                {(userPermissions.VisualizarTodosLasInformacionesClientes || userPermissions.VisualizarTodasLasInformacionesDelClienteMenosElId) ? (
                    <>
                        <div className={entry.logo_url ? 'client-card-logo client-card-logo-clear' : 'client-card-logo'}>
                            {entry.logo_url &&
                            <img src={entry.logo_url} alt={entry.name} />}
                        </div>
                        <div className='client-card-name'>
                            <h2>{entry.name}</h2>
                            <p>
                                <strong>RNC: {entry.rnc}</strong> <span>• {entry.segment} • {entry.sector} </span>

                                {userPermissions.VisualizarTodosLasInformacionesClientes &&
                                <span>• ID: {entry.id}</span>}
                            </p>
                        </div>

                        {(userPermissions.EditarDatosGeneralesClientes || userPermissions.EditarDatosFinancierosClientes) &&
                        <button className='client-card-edit-button' onClick={(e) => {e.preventDefault(); openEditClientModal(entry)}}><FontAwesomeIcon icon={faGear} /></button>}
                    </>
                ) : (
                    <div className='client-card-name'>
                        <h2>{entry.id}</h2>
                    </div>
                )}

            </div>
            <div className='client-card-balance'>
                {entry?.last_end_uploaded_month ? (
                    <div className='client-card-balance-entry'>
                        <p><strong>Ultimo mes cargado</strong></p>
                        <p>{Moment(entry?.last_end_uploaded_month).format('MMM yyyy')}</p>
                    </div>
                ) : (
                    <div className='client-card-balance-entry'>
                        <p><strong>Ultimo mes cargado</strong></p>
                        <p>N/D</p>
                    </div>
                )}

                {entry?.sales_level &&
                <div className='client-card-balance-entry'>
                    <p><strong>Nivel de ventas</strong></p>
                    <p>${new Intl.NumberFormat().format(entry?.sales_level)}</p>
                </div>}
            </div>
        </Link>
    </div>
);

const GridCard = ({entry, openEditClientModal, userPermissions, user}) => (
    <div className='client-card'>
        <Link className='client-card-link' to={`/client-detail/${entry.id}`}>

            {(userPermissions.VisualizarTodosLasInformacionesClientes || userPermissions.VisualizarTodasLasInformacionesDelClienteMenosElId) &&
            <div className='client-card-category'>
                <p>{truncateString(entry.sector, 30)}</p>

                {(userPermissions.EditarDatosGeneralesClientes || userPermissions.EditarDatosFinancierosClientes) &&
                <button className='client-card-edit-button' onClick={(e) => {e.preventDefault(); openEditClientModal(entry)}}><FontAwesomeIcon icon={faGear} /></button>}
                
            </div>}

            {(userPermissions.VisualizarTodosLasInformacionesClientes || userPermissions.VisualizarTodasLasInformacionesDelClienteMenosElId) ? (
                <>
                    <div className='client-card-details'>
                        <div className={entry.logo_url ? 'client-card-logo client-card-logo-clear' : 'client-card-logo'}>
                            {entry.logo_url &&
                            <img src={entry.logo_url} alt={entry.name} />}
                        </div>
                        <h2>{entry.name}</h2>
                    </div>
                    <div className='client-card-category client-card-rnc'>
                        <p>{truncateString(entry.segment, 30)}<br/>
                        RNC: {entry.rnc}
                        </p>

                        {userPermissions.VisualizarTodosLasInformacionesClientes &&
                        <p>ID: {entry.id}</p>}
                    </div>
                </>
            ) : (
                <div className='client-card-details'>
                    <h2>{entry.id}</h2>
                </div>
            )}

            <div className='client-card-balance'>
                {entry?.last_end_uploaded_month ? (
                    <div className='client-card-balance-entry'>
                        <p><strong>Ultimo mes cargado</strong></p>
                        <p>{Moment(entry?.last_end_uploaded_month).format('MMM yyyy')}</p>
                        {entry?.last_uploaded_balance_at ? (
                            <>
                            <p><strong>Ultima carga de datos</strong></p>
                            <p>{Moment(entry?.last_uploaded_balance_at).format('d/MM/yyyy')}</p>
                            </>
                            
                        ) : (
                            <>
                            <p><strong>Ultima carga de datos</strong></p>
                            <p>N/D</p>
                            </>
                            
                        )}
                        
                    </div>
                ) : (
                    <div className='client-card-balance-entry'>
                        <p><strong>Ultimo mes cargado</strong></p>
                        <p>N/D</p>
                        {entry?.last_uploaded_balance_at ? (
                            <>
                            <p><strong>Ultima carga de datos</strong></p>
                            <p>{Moment(entry?.last_uploaded_balance_at).format('d/MM/yyyy')}</p>
                            </>
                            
                        ) : (
                            <>
                            <p><strong>Ultima carga de datos</strong></p>
                            <p>N/D</p>
                            </>
                            
                        )}
                    </div>
                )}
                
                {entry?.sales_level &&
                <div className='client-card-balance-entry'>
                    <p><strong>Nivel de ventas</strong></p>
                    <p>${new Intl.NumberFormat().format(entry?.sales_level)}</p>
                </div>}
            </div>
        </Link>
    </div>
);